<template>
  <div class="container">
    <div class="u-text-align-center">
      <img src="@/assets/images/Logo.png" width="150" alt="" />
    </div>
    <h3 class="u-margin-top">
      Kişisel Verilerinizin İşlenmesine İlişkin Bilgilendirme Metni ve Kişisel
      Verilerin Korunması Politikamız
    </h3>
    <p>
      RezzApp (bundan böyle "Platform" olarak anılacaktır),
      <a href="https://rezzapp.com">https://rezzapp.com</a> adresinden ("Web
      Sitesi") erişilebilen, kayıtlı ofisi Adalet Mahallesi, Şehit Fethi Sekin
      Caddesi, No: 1 Martı Tower Kat: 13 Daire No 1304 BAYRAKLI/İZMİR adresinde
      bulunan çevrimiçi bir platformdur. Platformun kullanıcıları
      ("Kullanıcı(lar)") çeşitli dosya aktarım hizmetlerine ("Hizmet(ler)")
      erişme olanağına sahiptir. Platformun Kullanıcısı olmak bilgi toplamayı
      içerir. Bu bağlamda, verilerinizin korunması Şirket için mutlak bir
      önceliktir. Aşağıdaki maddeler,
      <a href="https://rezzapp.com">https://rezzapp.com</a> web sitesinde
      toplanan kişisel verilerin neler olduğu, söz konusu verilerin hangi
      amaçlar için toplandığı, hangi şekillerde kullanıldığı, bu bilgileri nasıl
      değiştirebileceğiniz ve bizimle nasıl iletişime geçebileceğiniz gibi
      konuları içermektedir.
    </p>

    <h4 class="u-color-primary u-margin-top-small">
      1. Kişisel Verilerin İşlenmesinin Yasal Dayanağı
    </h4>
    <p>
      6698 sayılı Kişisel Verilerin Korunması Kanunu, 6563 Sayılı Elektronik
      Ticaretin Düzenlenmesi Hakkında Kanun, 6502 sayılı Tüketicinin Korunması
      Hakkında Kanun, Elektronik Ticarette Hizmet Sağlayıcı ve Aracı Hizmet
      Sağlayıcılar Hakkında Yönetmelik ve Ticari İletişim ve Ticari Elektronik
      İletiler Hakkında Yönetmelik, kişisel verilerin işlenmesi ya da korunması
      ile ilgili hükümler içermektedir. 5237 Sayılı Türk Ceza Kanunu ise,
      kişisel verilerin işlenmesi ve gizliliği ile ilgili birtakım yaptırımlar
      öngörmüştür. Eralp Yazılım olarak, 6502 sayılı Tüketicinin Korunması
      Hakkında Kanun ve Mesafeli Sözleşmeler Yönetmeliği çerçevesinde siz
      müşterilerimizle kuracağımız sözleşmelerden doğan yükümlülüklerimizi
      yerine getirebilmek ve en iyi hizmeti verebilmek amacıyla siz üyelerimizin
      ve müşterilerimizin kişisel verilerinin işlenmesine ihtiyaç duymaktayız.
    </p>

    <h4 class="u-color-primary u-margin-top-small">2. Toplanan Bilgiler</h4>
    <p>
      Kullanıcıların Platformun Hizmetlerinden ve özelliklerinden tam olarak
      yararlanabilmeleri için RezzApp çeşitli bilgiler toplamaktadır. Web
      Sitesini kullanarak, hangi ülkeden bağlandığınıza bakılmaksızın bu
      bilgilerin toplanacağını açıkça kabul ettiğiniz hatırlatılır. Buna ek
      olarak, Şirkete veri iletmeniz sizin açınızdan tamamen gönüllü bir
      süreçtir. Platformun Genel Kullanım Koşulları ve bu Gizlilik Politikası
      uyarınca, onayınızın geri çekilmesinin geriye dönük olarak işlemeyeceğini
      bilerek ayarlarınızı değiştirerek istediğiniz zaman buna itiraz etme
      olanağına sahipsiniz.
    </p>
    <p>Bu bilgiler farklı zamanlarda ve çeşitli yöntemlerle toplanmaktadır:</p>
    <ul>
      <li>
        <strong>Platformun Kullanımı:</strong> Platformu kullandığınızda, Şirket
        sunucuları Hizmetlerimiz kullanılarak oluşturulan ve değiş tokuş edilen
        bilgileri otomatik olarak kaydeder. Örneğin, Şirket tüm oturum
        bilgilerinizden haberdardır (Platformda hangi sayfayı ziyaret ettiğiniz,
        hangi verileri girdiğiniz vb.). Ayrıca, Hizmetleri kullanımınız
        bağlamında, Şirket, Genel Kullanım Koşulları hükümlerine uygun olarak
        işlenen dosyalarda bulunabilecek kişisel verilerin işleyicisi olarak
        hareket eder.
      </li>
      <li>
        <strong>Üye Hesabı Oluşturma:</strong> Bir üye hesabı oluştururken: ad,
        soyad, e-posta adresi, telefon numarası, şifre, şirket (isteğe bağlı),
        posta adresi, posta kodu, şehir, ülke;
      </li>
    </ul>
    <p>
      Toplanan bilgiler, Platformda sunulan çeşitli Hizmetleri kullanmanızı
      sağlamak için kullanılır. Hizmetlere erişerek, Şirkete verilerinizi işleme
      yetkisi veren Platformun Genel Kullanım Koşullarını ve bu Gizlilik
      Politikasını gönüllü olarak ve açıkça kabul etmiş olursunuz. Kişisel
      verilerinizin korunması esas olduğundan, Şirket, bu Gizlilik Politikasında
      ve Platformun Genel Kullanım Koşullarında açıklanan koşullar haricinde,
      kişisel bilgilerinizi ve Platformda işlenen dosyaları satmamayı,
      kiralamamayı veya herhangi bir şekilde sağlamamayı taahhüt eder.
    </p>

    <h4 class="u-color-primary u-margin-top-small">
      3. Kişisel Verilerin İşlenmesinde Kullanılan Yöntem
    </h4>
    <p>
      Eralp Yazılım, siz müşterilerimizin ve üyelerimizin bilgilerinin
      güvenliğine büyük önem vermekte ve bunu sağlamak adına en ileri
      teknolojiye sahip araçlarla çalışmaktadır. Sitemizin güvenliğini sağlamak
      amacı ile güvenli ortamlarda her türlü fiziksel, elektronik ve yönetimsel
      önlem alınmıştır. Tüm bilgiler güvenli sunucularda saklanmaktadır.Şirket,
      web sitelerinin büyük çoğunluğu gibi çerezler kullanır. Çerezler,
      bilgisayarınızın sabit diskinde depolanan ve Platformda oturum açtığınızda
      sizi bir Kullanıcı olarak tanımlayan küçük veri dosyalarıdır. Diğer
      şeylerin yanı sıra, Platformun bağlantı ve kullanım istatistiklerini
      (Google Analytics) izlemeye izin verirler. Ancak, internet tarayıcınızdaki
      tercihlerinizi değiştirerek çerez kullanımını devre dışı bırakabilirsiniz.
      Bu durumda, Platformun belirli özellikleri artık çalışmayabilir.
    </p>

    <h4 class="u-color-primary u-margin-top-small">
      4. Toplanan Bilgilerin Saklanması
    </h4>
    <p>
      Bu bilgiler toplandıktan sonra, Hizmetlerin kullanımıyla bağlantılı olarak
      işlenmek üzere Şirketin sunucusunda saklanır. Platformun hizmetlerinin ve
      özelliklerinin en iyi şekilde çalışmasını sağlamak için, Kullanıcıların
      Profillerinde görünen bilgileri düzenli olarak güncellemeleri ve buna göre
      yanlış bilgileri düzeltmeleri veya silmeleri önerilir.
    </p>

    <h4 class="u-color-primary u-margin-top-small">
      5. Kişisel Verilerinizin İşlenme Amacı
    </h4>
    <p>
      Hizmetlerimizi ve kampanyalarımızı duyuran e-bültenlerimize ve sitemize
      üye olmanız, site ve uygulamalar üzerinden paket satın almanız ya da satın
      aldığınız paketi iptal etmeniz gibi durumlarda, sizlere daha iyi hizmet
      verebilmek adına ad, soyad, e-posta adresi, telefon numarası, fatura ve
      teslimat adresleri gibi bazı kişisel bilgilerinize ihtiyaç duyacağız. Bu
      bilgiler haricinde, sitenin ziyaret sıklığı ve zamanları gibi kişisel
      bilgi içermeyen toplu istatistiksel veriler de ziyaretçi hareket ve
      tercihlerini analiz etmek ve anlamak amacıyla toplanır ve kullanılır.
      https://rezzapp.com sitesinde toplanan kişisel bilgiler, müşterilerimizle
      ilgili iletişimi ve müşteri memnuniyetini sağlamak, kampanyalardan
      haberdar etmek ve istediğiniz veya yetkilendirdiğiniz hizmetlerin
      sağlanması ya da işlemlerin yerine getirilmesi için kullanılır.
      https://rezzapp.com bu kullanımları desteklemek üzere, müşterilerine daha
      etkili hizmet sunmak, siteyi, ve diğer ilgili Eralp Yazılım ürünlerini
      veya hizmetlerini iyileştirmek ve sitenin kullanımını daha kolay hale
      getirmek için kişisel bilgileri kullanabilir. Kişisel bilgilerinizi,
      bildirimleri de içerecek şekilde, kullanmakta olduğunuz ürün veya
      hizmetler hakkında bilgiler sağlamak için kullanabiliriz. Ayrıca, size
      diğer Eralp Yazılım ürünleri ve hizmetleri hakkında bilgiler
      gönderebiliriz. Bir haber bültenine kaydolduğunuzda veya tanıtım
      e-postaları almayı kabul ettiğinizde https://rezzapp.com sitesi,
      tıkladığınız e-postaları izlemek için özelleştirilmiş bağlantılar veya
      benzeri teknolojiler kullanabilir. Her e-posta, bu tür iletilerin
      gelmesini durdurmanıza olanak veren, abonelik iptal bağlantıları içerir.
      Kişisel verileriniz, RezzApp tarafından sunulan hizmetlerin
      gerçekleştirilmesi, müşteri ilişkilerinin yönetimi, hizmetlerimizin
      iyileştirilmesi ve geliştirilmesi, hukuki yükümlülüklerimizin yerine
      getirilmesi, güvenliğin sağlanması, pazarlama ve iletişim faaliyetlerinin
      yürütülmesi amacıyla işlenebilecektir.
    </p>

    <h4 class="u-color-primary u-margin-top-small">
      6. Kişisel Verilerinizin Aktarıldığı Kurumlar ve Aktarım Amacı
    </h4>
    <p>
      Eralp Yazılım, topladığı kişisel verileri faaliyetlerini yürütebilmek için
      işbirliği yaptığı program ortağı kurumlarla, kuruluşlarla, verilerin bulut
      ortamında saklanması hizmetini aldığı yurt içindeki/yurt dışındaki kişi ve
      kurumlarla, Bankalararası Kart Merkezi ile, TURK Elektronik Para A.Ş. ile
      müşterilerine daha iyi hizmet sunabilmek ve müşteri memnuniyetini
      sağlayabilmek için çeşitli pazarlama faaliyetleri kapsamında yardımcı
      araçlarla, yurt içindeki ve yurt dışındaki çeşitli ajanslarla ve reklam
      şirketleriyle, anket şirketleriyle, yurt içindeki/yurt dışındaki diğer
      üçüncü kişilerle ve ilgili iş ortakları ile paylaşabilmektedir. Açık rıza
      olmaksızın Eralp Yazılım’ın kişisel verilerinizi işlemesi, ancak 6698
      sayılı Kişisel Verilerin Korunması Kanunu’nda sınırlı sayıda sayılmış olan
      hallerde mümkündür. Hizmetlerin sunumunda bize yardımcı olmaları veya
      hizmetleri bizim adımıza sunmaları için başka şirketler, aracılar veya
      yükleniciler ("Hizmet Sağlayıcılar") kullanıyoruz. Örneğin Abonelik
      hizmetlerinin sürekliliğini sağlamak ve kullanıcılarımızın kesintisiz
      hizmet alabilmesi için, kullanıcıların ödeme bilgilerini (kredi kartı veya
      banka kartı bilgileri) güvenli, kolay ve hızlı bir ödeme sistemi olan
      ‘‘Lidio’’ tarafından kaydedilmektedir. Bu bilgiler, kullanıcıların her
      abonelik döneminde tekrar ödeme bilgisi girmelerine gerek kalmadan
      abonelik ücretlerinin otomatik olarak tahsil edilmesi amacıyla
      kullanılacaktır. Yalnızca Lidio tarafından kaydedilen bu bilgiler, RezzApp
      ile paylaşılmaz veya sunucularında saklanmaz. ''Lidio'' tarafından
      kaydedilen bilgiler hizmet sağlama ile bağlantılı işler dışında kullanılma
      veya açıklama yetkisine sahip değildir. Kullanıcılar, abonelik işlemi
      sırasında ödeme bilgilerinin kaydedilmesi ve işlenmesine yönelik onay
      vermektedirler. Bu onay, kullanıcı sözleşmesinin kabulü ile birlikte yasal
      olarak geçerli ve bağlayıcı bir hale gelmektedir.
    </p>

    <h4 class="u-color-primary u-margin-top-small">
      7. Kişisel Verilerinize Dair Haklarınız
    </h4>
    <p>
      Eralp Yazılım olarak, 6698 sayılı Kişisel Verilerin Korunması Kanunu
      uyarınca tanınmış kişisel verilerinizle ilgili haklarınızı hatırlatmak
      isteriz:
    </p>
    <ul>
      <li>Kişisel veri işlenip işlenmediğini öğrenme,</li>
      <li>Kişisel verileri işlenmişse buna ilişkin bilgi talep etme,</li>
      <li>
        Kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp
        kullanılmadığını öğrenme,
      </li>
      <li>
        Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü
        kişileri bilme,
      </li>
      <li>
        Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların
        düzeltilmesini isteme,
      </li>
      <li>
        7. maddede öngörülen şartlar çerçevesinde kişisel verilerin silinmesini
        veya yok edilmesini isteme,
      </li>
      <li>
        (d) ve (e) bentleri uyarınca yapılan işlemlerin, kişisel verilerin
        aktarıldığı üçüncü kişilere bildirilmesini isteme,
      </li>
      <li>
        İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz
        edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya çıkmasına
        itiraz etme,
      </li>
      <li>
        Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara
        uğraması hâlinde zararın giderilmesini talep etme.
      </li>
    </ul>

    <h4 class="u-color-primary u-margin-top-small">
      8. Kişisel Veri İşleme Süresi
    </h4>
    <p>
      Kişisel Verileriniz, KVKK başta olmak üzere ilgili kişisel verilerin
      korunması mevzuatı ve diğer mevzuatta zorunlu kılınan süreler uyarınca ve
      azami olarak yukarıdaki meşru amaçlar ortadan kalkmadığı müddetçe
      işlenecektir.
    </p>

    <h4 class="u-color-primary u-margin-top-small">
      9. Kişisel Verilerinizin Güncellenmesi ve Başvuru Hakkınız
    </h4>
    <p>
      Eralp Yazılım olarak, 6698 sayılı Kişisel Verilerin Korunması Kanunu
      uyarınca, işlenen kişisel verilerinizin doğru ve güncel olmasını sağlama
      yükümlülüğümüz bulunmaktadır. Bu yükümlülük çerçevesinde, bilgileriniz
      değiştiğinde onları güncelleyebilmemiz için
      <a href="https:/rezzapp.com">https:/rezzapp.com</a>'da iletişim
      bölümümüzde bulunan iletişim kanalları aracılığıyla bizimle iletişime
      geçmenizi rica ederiz.
    </p>
    <p>
      Bunun dışında yukarıda belirtmiş olduğumuz haklarınızı kullanmak
      istediğinizde yine aynı şekilde bizimle iletişime geçerek bize müracaat
      edebilirsiniz. Eralp Yazılım olarak, mevzuatta kişisel verilerle ilgili
      yapılabilecek herhangi bir değişiklik sonrasında gerekli güncellemeleri
      yapacağımızı taahhüt ederiz.
    </p>
    <p>
      Kişisel Verileri Eralp Yazılım tarafından işlenen müşterilerimiz
      tarafından yapılacak şikâyetler, Eralp Yazılım tarafından en kısa süre
      içerisinde ve en geç 30 gün içerisinde cevaplandırılacak ve
      sonuçlandırılacaktır. Ancak ek bir maliyet doğması halinde, Eralp
      Yazılım’ın Kişisel Verileri Koruma Kurulu tarafından belirlenecek tarifeye
      göre tarafınızdan ücret talep edebilme hakkı saklıdır.
    </p>
    <p>
      Müşterilerimiz, talep ve şikâyetlerini 6698 sayılı Kişisel Verilerin
      Korunması Kanunu ve ilgili mevzuat uyarınca
      <a href="https:/rezzapp.com">https:/rezzapp.com</a>
      adresine yapabilecektir. Bu kapsamda, “7. Kişisel Verilerinize Dair
      Haklarınız” başlığı altında sıralanan haklarınıza ilişkin taleplerinizi
      kimliğinizi tespit edici belgelerle birlikte aşağıda belirlenen yöntemler
      ile Şirket’e iletebilirsiniz:
    </p>
    <ul>
      <li>
        (i) Aşağıda yer alan ve zaman içerisinde güncellenebilecek olan posta
        adresimize elden ıslak imzalı olarak ya da noter aracılığıyla:<br />
        <strong>Adres:</strong> Adalet Mahallesi, Şehit Fethi Sekin Caddesi, No:
        1 Martı Tower Kat: 13 Daire No 1304 BAYRAKLI/İZMİR
      </li>
      <li>
        (ii) Bize daha önce bildirdiğiniz ve sistemimizde kayıtlı olan e-posta
        adresinizden tarafımıza göndereceğiniz e-posta ile (lütfen kayıtlı
        e-posta adresinizi kontrol ediniz):<br />
        <strong>E-posta adresi:</strong>
        <a href="mailto:info@rezzapp.com">info@rezzapp.com</a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
